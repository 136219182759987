<template>
  <el-form :model="companyFormData" ref="ruleForm" >
    <h1 class="sub-module-title">{{title}}</h1>
    <div class="sub-module-block">
      <div class="div-frac-average-14">
        <el-form-item label="Company Name"  :rules="rules.reqRule" prop="sysOrganizationId">
          <el-select
              filterable
              v-model="companyFormData.sysOrganizationId"
              value-key="counterpartySysOrganizationId"
              :disabled="isAllFieldsDisabled || isEdit || (isSellerForm&& termContractType === TERM_CONTRACT_TYPE.TC || isSellerForm&& termContractType === TERM_CONTRACT_TYPE.COA)"
              @change="$emit('getBargesListBySeller',$event, isSellerForm)"
            >
              <el-option
                v-for="item in sortByAlphabetical(companyOptionList, 'counterpartyCompanyName')"
                :key="item.counterpartySysOrganizationId"
                :label="item.counterpartyCompanyName"
                :value="item.counterpartySysOrganizationId"
                :disabled="item.status !== 'ACTIVE'"
              />
          </el-select>
        </el-form-item>
        <el-form-item label="In-Charge" :rules="rules.reqRule" prop="id">
          <el-input
            v-if="isAllFieldsDisabled"
            v-model="companyFormData.name"
            disabled/>
          <el-select
              v-else
              filterable
              allow-create
              v-model="companyFormData.id"
              value-key="id"
              :disabled="!companyFormData.sysOrganizationId || !hasEditPermission"
              @change="handleInChargeChange"
            >
               <el-option
                v-for="item in sortByAlphabetical(inChargeOptionsList,'name')"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Email Address" :rules="GENERAL_EMAIL_RULE_REQ"  prop="email">
          <el-input v-model="companyFormData.email"  :disabled="isAllFieldsDisabled  || !hasEditPermission || !companyFormData.sysOrganizationId"/>
        </el-form-item>
        <el-form-item label="Reference No." prop="referenceNo" v-if="isShow">
          <el-input v-model="companyFormData.referenceNo"  :disabled="isAllFieldsDisabled || !hasEditPermission || !companyFormData.sysOrganizationId"/>
        </el-form-item>
      </div>
      <div class="div-frac-1" v-if="isShow">
        <el-form-item label="Remark" prop="remark">
          <el-input
            v-model="companyFormData.remark"
            :disabled="isAllFieldsDisabled || !hasEditPermission"
            type="textarea"
            :rows="1"
          />
        </el-form-item>
      </div>
    </div>

  </el-form>
</template>
<script>
import { PAYMENT_TERM_OPTIONS, TERM_CONTRACT_TYPE, GENERAL_EMAIL_RULE_REQ } from '@/constants.js'
import { sortByAlphabetical } from '@/utils'
export default {
  name: 'CompanyInfomation',
  props: {
    title: String,
    companyType: String,
    termContractType: String,
    formData: Object,
    rules: Object,
    currentCompany: Object,
    isViewOnly: Boolean,
    isEdit: Boolean,
    hasEditPermission: Boolean,
    isSellerForm: Boolean,
    isAllFieldsDisabled: Boolean,
    companyOptionList: Array

  },
  data () {
    const initFormData = {
      sysOrganizationId: null,
      id: null,
      email: null,
      referenceNo: null,
      // creditDay: null,
      // paymentTerm: null,
      remark: null,
      name: null
    }
    return {
      initFormData,
      PAYMENT_TERM_OPTIONS,
      TERM_CONTRACT_TYPE,
      GENERAL_EMAIL_RULE_REQ,
      companyFormData: initFormData,
      currentCompanyUserList: this.$store.state.currentCompanyUserList,
      inChargeOptionsList: [],
      selectedCompany: null,
      isOnline: Object.keys(this.$store.state.currentCompany).length > 0
    }
  },
  computed: {
    isShow () {
      return this.currentCompany?.id === this.companyFormData?.sysOrganizationId
    }
  },
  mounted () {
    if (this.formData && this.isEdit) {
      let txt
      if (this.isSellerForm) {
        txt = 'seller'
        this.formatbuyerAndSellerData(txt)
      } else {
        txt = 'buyer'
        this.formatbuyerAndSellerData(txt)
      }
    } else {
      if (this.isSellerForm) {
        /*
         *预留 变更create逻辑处理函数
        */
        // this.companyRolePermission()
        if ([this.TERM_CONTRACT_TYPE.COA, this.TERM_CONTRACT_TYPE.TC].includes(this.termContractType)) {
          this.companyFormData.sysOrganizationId = this.currentCompany.id
          this.$emit('getBargesListBySeller', this.currentCompany.id, true)
        }
      }
    }
  },
  methods: {
    sortByAlphabetical,
    formatbuyerAndSellerData (txt) {
      Object.keys(this.companyFormData).forEach(key => {
        const fieldKey = txt + key.replace(/^\S/, s => s.toUpperCase())
        if (this.formData[fieldKey]) {
          this.companyFormData[key] = this.formData[fieldKey]
        }
      })
    },
    getUserListByCompanyId (companyId, isOnline) {
      if (isOnline) {
        return new Promise((resolve, reject) => {
          this.$request.get({
            url: `${this.$apis.sharedUserBaseUrl}?pageNumber=1&pageSize=99999&organizationId=${companyId}`,
          }).then(res => {
            if (res?.code === 1000) {
              const userList = res?.data?.records || []
              resolve(userList)
            }
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          if (this.isOnline) {
            this.$request.get({
              url: `${this.$apis.offlineOrganizationContact}?filter=sysOfflineOrganizationId : '${companyId}'&pageNumber=0&pageSize=9999`
            }).then(res => {
              if (res?.code === 1000) {
                const contactList = res.data.content
                resolve(contactList)
              }
            })
          } else {
            resolve([])
          }
        })
      }
    },
    handleInChargeChange (val) {
      this.companyFormData.email = this.inChargeOptionsList.filter(user => user.id === val)[0]?.email
    },
    getCompanyFormData () {
      let isValid
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          isValid = true
        } else {
          isValid = false
          return null
        }
      })
      if (isValid) {
        return this.companyFormData
      }
    }
  },
  watch: {
    'companyFormData.sysOrganizationId': {
      immediate: false,
      handler (newValue, prevValue) {
        if (newValue && newValue !== prevValue) {
          const resetData = this.$_.cloneDeep(this.initFormData)
          delete resetData.referenceNo
          delete resetData.remark
          delete resetData.sysOrganizationId
          this.companyFormData = { ...this.companyFormData, ...resetData }
          const selectedCompany = this.companyOptionList.filter(company => company.counterpartySysOrganizationId === newValue)[0]
          if (newValue === this.$store.state.currentCompany.id) {
            this.inChargeOptionsList = this.currentCompanyUserList
          } else {
            selectedCompany && this.getUserListByCompanyId(newValue, selectedCompany.isOnlineCounterparty).then(res => {
              this.inChargeOptionsList = res
            })
          }
        }
      }
    }
  }
}
</script>
<style scoped lang="scss">
  @import "../index.scss";
</style>
